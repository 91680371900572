import styled from "styled-components";
import { mq } from "../../globals/utils";

export const StyledUserBrandWrapper = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px 20px; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .breakFlex {
    flex-basis: 100%;
    height: 24px;
  }
  .brandImg {
    text-align: center;
    line-height: 70px;
    &.removeMaxHt {
      img {
        max-height: none;
      }
    }
    img {
      display: inline-block;
      max-height: 70px;
    }
  }
  .eventsImg {
    img {
      max-height: 54px;
    }
  }
  ${mq.between("md", "lg")} {
    grid-gap: 24px;
    .breakFlex {
      height: 0px;
    }
    .contactUsBreak {
      display: none;
    }
    .contactUsbrandImg {
      width: 45%;
      display: inline-block;
    }
  }
  ${mq.below.md} {
    .breakFlex {
      height: 0px;
      display: none;
    }
    .brandImg {
      width: 50%;
      display: inline-block;
      text-align: left;
      &:nth-of-type(even) {
        text-align: right;
      }
    }
    .contactUsbrandImg {
      width: 50%;
      display: inline-block;
    }
  }
`;
export default StyledUserBrandWrapper;
