import React, { useState, useEffect, Fragment } from "react";

import { Image } from "../../globals/UIKit";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledUserBrandWrapper from "../shared/StyledUserBrandWrapper";
import { StyledBody1 } from "../shared/StyledTypography";
import { removePaddTop, removePaddBottom, textCenter } from "../shared/CommonStyled";

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowWidth, setWindowSize] = useState(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize(window.innerWidth);
    }
    // Add event listener
    // if (!!window.Intercom) {
    //   window.Intercom("update", { hide_default_launcher: true });
    // }
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowWidth;
}

const UserBrand = ({ userBrandState, isRemovePaddTop, isRemovePaddBottom, title, imgClass }) => {
  const windowWidth = useWindowSize();

  const isMobile = windowWidth && windowWidth <= 767 ? true : false;

  return (
    <StyledSectionWrapper
      css={
        (isRemovePaddTop ? removePaddTop : "") + " " + (isRemovePaddBottom ? removePaddBottom : "")
      }
    >
      <StyledContainerWrapper>
        {
          title && (<StyledBody1 paddingBottom="pb24" fontWeight="font_600" css={textCenter}>{title}</StyledBody1>)
        }
        <StyledUserBrandWrapper>
          {userBrandState.map((brand, index) => (
            <Fragment key={index}>
              {brand.break ? (
                <>{!isMobile && <div className="breakFlex"></div>}</>
              ) : (
                <div className={"brandImg" + ((imgClass) ? " removeMaxHt" : "")}>
                  <Image
                    src={brand.imgSrc}
                    alt={brand.altText}
                    width={brand.width}
                    height={brand.height}
                  />
                </div>
              )}
            </Fragment>
          ))}
        </StyledUserBrandWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default UserBrand;
