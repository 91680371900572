import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import { Flex } from "../../globals/UIKit";
import { Icon } from "../../globals/icons";
import {
  StyledSubTitle2,
  StyledBody1,
  StyledDesc1,
  StyledDesc2,
  StyledDesc3,
} from "../shared/StyledTypography";
// import { flexCenter } from "../shared/CommonStyled";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";
import { scrollToTop } from "../../utils/helpers";

const StyledContactBox = styled.div`
  /* background-color: #fff; */
  /* box-shadow: 0px 3px 16px rgba(28, 38, 63, 0.08), 0px 4px 6px rgba(28, 38, 63, 0.03); */
  /* border-radius: 16px; */
  /* padding: 32px 40px; */
  margin-top: 40px;
  width: 100%;
  /* max-width: 390px; */

  .rightIcon {
    transition: all 0.2s ease-in-out;
  }

  a {
    /* max-height: 115px; */

    &:hover {
      .rightIcon {
        transform: translateX(4px);
      }
    }
  }

  @media (max-width: 600px) {
    align-items: flex-start;

    a {
      display: flex;
      align-items: flex-start;
    }

    .rightIcon {
      min-width: 16px;
      max-width: 16px;
    }
  }

  .contractUsWrapper {
    /* align-self: end; */

    &.contactUsMobileShow {
      display: none;
    }
    svg {
      margin-right: 8px;
    }
    .alignItemEnd {
      display: flex;
      align-items: end;
      padding-top: 24px;
      svg {
        margin-left: 24px;
      }
    }
  }

  ${mq.between("md", "lg")} {
    .contractUsWrapper {
      width: max-content;
      &.contactUsMobileHide {
        display: none;
      }
      &.contactUsMobileShow {
        display: block;
        margin-top: 40px;
      }
    }
  }
`;

export const ContactUsBox = () => (
  <StyledContactBox>
    <a href="/contact-us/" className="contractUsWrapper" onClick={scrollToTop}>
      <Flex width="100%" justifyContent="center">
        <Mail />
        <StyledBody1 variant="neutral_1000" fontWeight="font_500">
          Got more questions about the event?
        </StyledBody1>
        <Icon className="rightIcon" variant="arrow_right" size="md" color="neutral_1000" ml="8px" />
      </Flex>
    </a>
  </StyledContactBox>
);

export const speakersState = [
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675506226/main-web/roadshow/tanmai-gopal_f9s5mq.png",
    name: "Tanmai Gopal",
    designation: "CEO & Co-Founder of Hasura",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1677167364/main-web/roadshow/jesse-martin_avtwms.png",
    name: "Jesse Martin",
    designation: "Developer Advocate, Hasura",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1677653819/main-web/roadshow/rahul-agarwal_rxnhiu.png",
    name: "Rahul Agarwal",
    designation: "Senior Product Manager, Hasura",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1679469672/main-web/roadshow/david-meleney_wn0phr.png",
    name: "David Meleney",
    designation: "Senior Product Manager, Hasura",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1679469696/main-web/roadshow/zach-goldberg_yhugmm.png",
    name: "Zach Goldberg",
    designation: "CTO, Equi",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1679471061/main-web/roadshow/adam-nazar_nxrbhq.png",
    name: "Adam Nazar",
    designation: "CTO, RxVantage",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1679471061/main-web/roadshow/dikshant-adhikari_xyakhy.png",
    name: "Dikshant Adhikari",
    designation: "Product Manager, Cockroach Labs",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1679471061/main-web/roadshow/shashiraj-jeripotula_ugarbc.png",
    name: "Shashiraj Jeripotula",
    designation: "Senior Partner Solutions Architect, Amazon Web Services",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1679984873/main-web/roadshow/richard-michaud_xbwc4w.png",
    name: "Richard Michaud",
    designation: "Director, Solution Engineering, Yugabyte",
  },
  {
    imgSrc: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1685026677/image_111_yyypkl.png",
    name: "Rahul Shekhar Pandey",
    designation: "Infrastructure Modernization Customer Engineer, Google",
    // designation: (
    //   <span style={{ display: "inline-block", marginRight: "-5px" }}>
    //     Infrastructure Modernization Customer Engineer
    //   </span>
    // ),
    // companyImgSrc: `https://res.cloudinary.com/dh8fp23nd/image/upload/v1685026783/Frame_13047_mpoxwt.png`,
    // companyImgWidth: "72px",
  },
];

const agendaState = [
  {
    time: "8AM - 9AM",
    title: "Registration, Networking, and Breakfast",
  },
  {
    time: "9AM - 12PM",
    title: "Workshop: Getting Started with Hasura",
  },
  {
    time: "12PM - 1PM",
    title: "Registration, Lunch, and Networking",
  },
  {
    time: "1PM - 1:45PM",
    title: "Data APIs: A Stable Delivery Interface for a Chaotic Data-First World",
  },
  {
    time: "1:45PM - 2:15PM",
    title: "Hear from a Hasura Customer",
  },
  {
    time: "2:15PM - 2:45PM",
    title: "Activate Your Snowflake Data with GraphQL APIs",
  },
  {
    time: "2:45PM - 3:15PM",
    title: "Afternoon Break",
  },
  {
    time: "3:15PM - 4:00PM",
    title: "Building Secure APIs: A Deep Dive of Authorization Best Practices",
  },
  {
    time: "4:00PM - 4:30PM",
    title: "Hear from Hasura Customer",
  },
  {
    time: "4:30PM - 4:45PM",
    title: "Expert Panel and Live Q&A",
  },
  {
    time: "4:45PM - 5:00PM",
    title: "Closing Remarks",
  },
  {
    time: "5:00PM - 7:00PM",
    title: "Happy Hour",
  },
];

const StyledFeaturedWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 140px;
  .grid {
    display: grid;
  }
  .alignSelfStart {
    align-self: start;
  }
  .speakersWrapper {
    padding-top: 32px;
    .speakersList {
      display: flex;
      align-items: center;
      margin-bottom: 48px;
      .speakerImg {
        padding-right: 24px;
        img {
          box-shadow: 0px 10.33px 24.4444px -6.11111px rgba(0, 64, 224, 0.29),
            0px 6.11111px 9.16667px -3.05556px rgba(0, 58, 204, 0.03);
          border-radius: 24px;
          border: 4px solid ${COLORS.white};
          min-width: 112px;
          max-width: 112px;
        }
      }
    }
  }
  .contractUsWrapper {
    align-self: end;
    background: ${COLORS.white};
    box-shadow: 0px 3px 16px rgba(28, 38, 63, 0.08), 0px 4px 6px rgba(28, 38, 63, 0.03);
    border-radius: 16px;
    padding: 32px 40px;
    &.contactUsMobileShow {
      display: none;
    }
    svg {
      margin-right: 8px;
    }
    .alignItemEnd {
      display: flex;
      align-items: end;
      padding-top: 24px;
      svg {
        margin-left: 24px;
      }
    }
  }
  .agendaWrapper {
    padding-top: 32px;
    .agendaList {
      padding: 24px 16px;
      border: 1px solid #d2d6db;
      border-radius: 12px;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
      .timeTag {
        padding: 4px 12px;
        background: #f0f4ff;
        border-radius: 100px;
        margin-right: 24px;
        display: inline-flex;
        min-width: fit-content;
      }
    }
  }
  ${mq.between("md", "lg")} {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    .contractUsWrapper {
      width: max-content;
      &.contactUsMobileHide {
        display: none;
      }
      &.contactUsMobileShow {
        display: block;
        margin-top: 40px;
      }
    }
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    .speakersWrapper {
      .speakersList {
        .speakerImg {
          img {
            min-width: 90px;
          }
        }
      }
    }
    .contractUsWrapper {
      width: auto;
      &.contactUsMobileHide {
        display: none;
      }
      &.contactUsMobileShow {
        display: block;
        margin-top: 32px;
      }
    }
    .agendaWrapper {
      .agendaList {
        display: block;
        .timeTag {
          margin-right: 0px;
          margin-bottom: 16px;
        }
      }
    }
  }
`;

const Mail = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7322 3.62223L22.2603 8.51544C22.5263 8.68834 22.6593 8.77479 22.7556 8.89011C22.8409 8.99219 22.905 9.11026 22.9441 9.23742C22.9883 9.38106 22.9883 9.53969 22.9883 9.85695V17.1887C22.9883 18.8688 22.9883 19.7089 22.6613 20.3506C22.3737 20.9151 21.9147 21.3741 21.3503 21.6617C20.7085 21.9887 19.8684 21.9887 18.1883 21.9887H7.78828C6.10812 21.9887 5.26805 21.9887 4.62631 21.6617C4.06182 21.3741 3.60288 20.9151 3.31526 20.3506C2.98828 19.7089 2.98828 18.8688 2.98828 17.1887V9.85695C2.98828 9.53969 2.98828 9.38106 3.03245 9.23742C3.07155 9.11026 3.13563 8.99219 3.22093 8.89011C3.31729 8.77479 3.45029 8.68834 3.7163 8.51544L11.2443 3.62223M14.7322 3.62223C14.101 3.21192 13.7854 3.00676 13.4453 2.92694C13.1447 2.85638 12.8319 2.85638 12.5313 2.92694C12.1912 3.00676 11.8756 3.21192 11.2443 3.62223M14.7322 3.62223L20.9244 7.64714C21.6123 8.09424 21.9562 8.31779 22.0753 8.60129C22.1794 8.84904 22.1794 9.12826 22.0753 9.37601C21.9562 9.65951 21.6123 9.88306 20.9244 10.3302L14.7322 14.3551C14.101 14.7654 13.7854 14.9705 13.4453 15.0504C13.1447 15.1209 12.8319 15.1209 12.5313 15.0504C12.1912 14.9705 11.8756 14.7654 11.2443 14.3551L5.05214 10.3302C4.36429 9.88306 4.02037 9.65951 3.90126 9.37601C3.79716 9.12826 3.79716 8.84904 3.90126 8.60129C4.02037 8.31779 4.36429 8.09424 5.05214 7.64714L11.2443 3.62223M22.4883 19.9887L15.8455 13.9887M10.1311 13.9887L3.48828 19.9887"
      stroke="#000615"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const FeaturedSpeakers = () => {
  return (
    <StyledContainerWrapper>
      <StyledFeaturedWrapper>
        <div className="grid">
          <div className="alignSelfStart">
            <StyledSubTitle2 variant="neutral_500" fontWeight="font_bold">
              Featured speakers
            </StyledSubTitle2>
            <div className="speakersWrapper">
              {speakersState.map((speaker, index) => (
                <div key={index} className="speakersList">
                  <div className="speakerImg">
                    <img src={speaker.imgSrc} alt={speaker.name} />
                  </div>
                  <div>
                    <StyledDesc1 variant="neutral_800" fontWeight="font_bold" paddingBottom="pb4">
                      {speaker.name}
                    </StyledDesc1>
                    <StyledDesc2 variant="neutral_800">{speaker.designation}</StyledDesc2>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <StyledSubTitle2 variant="neutral_500" fontWeight="font_bold">
            Agenda
          </StyledSubTitle2>
          <div className="agendaWrapper">
            {agendaState.map((agenda, index) => (
              <div className="agendaList">
                <StyledDesc3 className="timeTag" variant="neutral_700" fontWeight="font_500">
                  {agenda.time}
                </StyledDesc3>
                <StyledDesc2 variant="neutral_900" fontWeight="font_500">
                  {agenda.title}
                </StyledDesc2>
              </div>
            ))}
          </div>
        </div>
      </StyledFeaturedWrapper>
    </StyledContainerWrapper>
  );
};

export default FeaturedSpeakers;
