import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import { ContactUsBox } from "./FeaturedSpeakers";
import { StyledSubTitle2, StyledBody1, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";
import { mq } from "../../globals/utils";
import { COLORS } from "../../globals/designSystem";

const StyledQuoteWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 0px;
  border: 1px solid #d2d6db;
  border-radius: 12px;
  .quoteBrand {
    background-color: ${COLORS.neutral_100};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px 0 0 12px;
  }
  .p56 {
    padding: 56px;
  }
  .quoteAuther {
    padding-top: 40px;
    display: flex;
    align-items: center;
    .autherImg {
      padding-right: 32px;
      img {
        box-shadow: 0px 10.33px 24.4444px -6.11111px rgba(0, 64, 224, 0.29),
          0px 6.11111px 9.16667px -3.05556px rgba(0, 58, 204, 0.03);
        border-radius: 24px;
        border: 4px solid ${COLORS.white};
        max-width: 75px;
      }
    }
  }
  ${mq.between("md", "lg")} {
    .p56 {
      padding: 40px;
    }
    .quoteAuther {
      .autherImg {
        padding-right: 24px;
      }
    }
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    .p56 {
      padding: 24px;
    }
    .quoteBrand {
      padding: 40px;
      border-radius: 12px 12px 0 0;
    }
    .quoteAuther {
      .mfs14 {
        font-size: 14px;
      }
    }
  }
`;

const Quote = () => {
  return (
    <StyledSectionWrapper padding="60px 0 100px">
      <StyledContainerWrapper>
        <StyledSubTitle2 variant="neutral_500" fontWeight="font_bold" paddingBottom="pb32">
          5 of the 10 Fortune 10 companies use Hasura
        </StyledSubTitle2>
        {/* <StyledQuoteWrapper>
          <div className="quoteBrand">
            <img
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1679302131/main-web/roadshow/optum_koz0nf.svg"
              alt="Optum"
            />
          </div>
          <div className="p56">
            <StyledBody1 variant="neutral_800" fontWeight="font_500">
              From the initial concept, it took just 100 days to get our new clinical platform
              running in a production environment… we don’t think we could have achieved this
              unbelievable speed without Hasura. We have been able to get to market much, much
              faster.
            </StyledBody1>
            <div className="quoteAuther">
              <div className="autherImg">
                <img
                  src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1679302435/main-web/roadshow/nagaraja-nayak_l4xte5.png"
                  alt="Nagaraja Nayak"
                />
              </div>
              <div>
                <StyledDesc1 variant="neutral_800" fontWeight="font_600">
                  Nagaraja Nayak
                </StyledDesc1>
                <StyledDesc2 className="mfs14" variant="neutral_800" fontWeight="font_500">
                  VP Enterprise Clinical Tech, Optum
                </StyledDesc2>
              </div>
            </div>
          </div>
        </StyledQuoteWrapper> */}
        <ContactUsBox />
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default Quote;
