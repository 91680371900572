import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import HeroBanner from "../components/roadshow/HeroBanner";
import UpcomingEvents from "../components/roadshow/UpcomingEvents";
import WhyAttend from "../components/roadshow/WhyAttend";
import FeaturedSpeakers from "../components/roadshow/FeaturedSpeakers";
import Quote from "../components/roadshow/Quote";

const ogImage = {
  ogImage:
    "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675698063/main-web/roadshow/data-api-days_obknrj.png",
};

const canonicalUrl = "https://hasura.io/data-api-days/";

const Roadshow = props => (
  <Layout location={props.location}>
    <Seo
      title="Data & API Days by Hasura"
      description="Join us for a full  day of insights, interaction, and networking with local peers. With workshops, sessions, a live demo, and more, you’ll walk away with a solid understanding of how Hasura is revolutionizing the way companies manage and access data."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <HeroBanner />
    <UpcomingEvents />
    <WhyAttend />
    <FeaturedSpeakers />
    <Quote />
  </Layout>
);

export default Roadshow;
