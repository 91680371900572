import React from "react";
import styled from "styled-components";

import { Image } from "../../globals/UIKit";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import { StyledPresentedWrapper } from "./DetailsPage";
import { StyledTitle2B, StyledDesc2, StyledSubTitle1 } from "../shared/StyledTypography";
import { textCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

const StyledSectionWrapperBlue100Bg = styled(StyledSectionWrapper)`
  background-image: url("https://res.cloudinary.com/dh8fp23nd/image/upload/v1675439634/main-web/roadshow/hero-bg_xaaan8.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0 0;
  .bgBlenMode {
    padding: 100px 0;
    background: linear-gradient(
      16.39deg,
      rgba(3, 21, 48, 0.7) 14.39%,
      rgba(48, 18, 97, 0.294) 100.34%
    );
    mix-blend-mode: normal;
    backdrop-filter: blur(36px);
  }
  ${mq.below.md} {
    .bgBlenMode {
      padding: 50px 0;
    }
  }
`;

const StyledTopBannerWrapper = styled.div`
  padding-bottom: 230px;
  .mWd600 {
    max-width: 600px;
    margin: 0 auto;
  }
  .mW500 {
    max-width: 500px;
    margin: 0 auto;
  }
  .dataApiIcon {
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 14.3333px 27.11px -4.77778px rgba(9, 17, 36, 0.5),
      0px 4.77778px 7.16667px -2.38889px rgba(0, 58, 204, 0.03);
    border-radius: 19px;
    width: 86px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 32px;
    img {
      display: inline-block;
      max-width: 55px;
    }
  }
  .textShadow {
    text-shadow: 0px 1px 3px rgba(28, 38, 63, 0.1), 0px 1px 2px rgba(28, 38, 63, 0.06);
  }
  ${mq.between("lg", "xl")} {
  }
  ${mq.below.lg} {
  }
`;

const StyledPresentedWrapperCenter = styled(StyledPresentedWrapper)`
  justify-content: center;
  padding-top: 50px;
  flex-direction: column;
  .flexCenter {
    display: flex;
    align-items: center;
    padding-top: 32px;
  }
  .brandImg {
    padding-left: 32px;
  }
  ${mq.between("md", "lg")} {
    .brandImg {
      padding-left: 24px;
    }
  }
  ${mq.below.md} {
    text-align: center;
    .flexCenter {
      display: block;
      padding: 20px 0;
    }
    .brandImg {
      width: 50%;
      display: inline-block;
      text-align: center;
      padding: 16px 16px;
      img {
        /* max-height: 28px; */
        /* max-width: fit-content !important; */
        &.awsImg {
          /* max-height: 33px; */
        }
      }
    }
  }
`;

const presentedBy = {
  title: "Presented by",
  presentedBrand: [
    {
      imgSrc:
        "https://res.cloudinary.com/dh8fp23nd/image/upload/v1678303397/main-web/hasura_logo_dark_sclxsr.png",
      imgMWd: "120px",
      imgAlt: "Hasura",
    },
    {
      imgSrc:
        "https://res.cloudinary.com/dh8fp23nd/image/upload/v1678952322/main-web/roadshow/aws_adndbi.png",
      imgMWd: "49px",
      imgAlt: "AWS",
      imgClass: "awsImg",
    },
    {
      imgSrc:
        "https://res.cloudinary.com/dh8fp23nd/image/upload/v1678952322/main-web/roadshow/cockroachdb_ktxqra.png",
      imgMWd: "175px",
      imgAlt: "CockroachDB",
    },
    {
      imgSrc:
        "https://res.cloudinary.com/dh8fp23nd/image/upload/v1681403720/main-web/roadshow/yugabyte_fosamf.svg",
      imgMWd: "166px",
      imgAlt: "Yugabyte",
    },
    {
      imgSrc:
        "https://res.cloudinary.com/dh8fp23nd/image/upload/v1682071640/main-web/roadshow/slower_q3hvxh.png",
      imgMWd: "94px",
      imgAlt: "Slower",
    },
  ],
};

const HeroBanner = () => {
  return (
    <StyledSectionWrapperBlue100Bg>
      <div className="bgBlenMode">
        <StyledContainerWrapper>
          <StyledTopBannerWrapper>
            <div className="mWd600">
              <div className="dataApiIcon">
                <img
                  src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675439595/main-web/roadshow/data-api-icon_kpvdph.png"
                  alt="Icon"
                />
              </div>
              <StyledTitle2B
                className="textShadow"
                variant="white"
                paddingBottom="pb16"
                fontWeight="font_bold"
                css={textCenter}
              >
                Data + API Days
              </StyledTitle2B>
              <StyledSubTitle1
                className="textShadow mW500"
                variant="white"
                fontWeight="font_400"
                css={textCenter}
              >
                Get on the GraphQL edge
              </StyledSubTitle1>
            </div>
            <StyledPresentedWrapperCenter>
              <StyledDesc2 className="textShadow" variant="white">
                {presentedBy.title}
              </StyledDesc2>
              <div className="flexCenter">
                {presentedBy.presentedBrand.map((brand, index) => (
                  <div className="brandImg" key={index}>
                    <Image
                      src={brand.imgSrc}
                      alt={brand.imgAlt}
                      maxWidth={brand.imgMWd}
                      width="100%"
                      className={brand.imgClass ? brand.imgClass : ""}
                    />
                  </div>
                ))}
              </div>
            </StyledPresentedWrapperCenter>
          </StyledTopBannerWrapper>
        </StyledContainerWrapper>
      </div>
    </StyledSectionWrapperBlue100Bg>
  );
};

export default HeroBanner;
